<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <PaymentDetail :paymentDetails="paymentDetails" :paymentDetailsProducts="paymentDetailsProducts" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import PaymentDetail from "../components/paymentreceivable/PaymentDetail.vue";
import axios from "axios";

export default {
  name: "PaymentReceivableDetail",
  components: {
    LeftMenu,
    Navbar,
    PaymentDetail,
  },
    data: function() {
    return {
      moduleName: "",
      errorMessage: null,
      paymentDetails: {},
      showLoader: 0,
      success: null,
      error: null,
      sale_id:'',
      paymentDetailsProducts: []
    };
  },
   methods: {
    fetchPaymentDetail() {
      var formData = new FormData();
      console.log(this.sale_id)
      formData.append("sale_id", this.sale_id);
      axios
        .post(
          `${process.env.VUE_APP_API_URL}paymentReceivable/details`, formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.paymentDetails = response.data.data;
            this.paymentDetailsProducts = response.data.data.saleDetail
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    getLinkUrl() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.sale_id = segment_array.pop();
      if (this.sale_id !== "payment-receivable-detail") {
        this.fetchPaymentDetail();
      }else{
        this.$router.push({ name: "PaymentReceivable" });
      }
    },

  },

  mounted() {
    this.getLinkUrl();
  },
};
</script>

<style></style>
