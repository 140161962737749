<template>
  <div>
    <div class="c-body">
      <div class="info-top">
        <h6>
         Credit Note
        </h6>
        <router-link class="custom-btn4" to="/payment-receivable">
          <span>Back</span>
        </router-link>
      </div>
      <main class="c-main">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 p-0">
              <div class="card">
                <div class="card-header" v-if="paymentDetails.sale">
                  <div class="ref">Reference: <strong>{{paymentDetails.sale.reference}} </strong></div>
                  <button
                    type="button"
                    class="custom-btn2"
                    @click="print(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Print</span>
                  </button>
                </div>
                <div class="card-body">
                  <div class="row mb-4">
                    <div class="col-sm-4 mb-3 mb-md-0" v-if="this.user && this.user.data.companyData!=null">
                      <h6 class="mb-2 border-bottom pb-2">Company Info:</h6>
                      <div><strong>{{this.user.data.companyData.company_name}}</strong></div>
                      <div>{{this.user.data.companyData.address}}</div>
                      <div>Email: {{this.user.data.companyData.company_email}}</div>
                      <div>Phone: {{this.user.data.companyData.phone_number}}</div>
                    </div>

                    <div class="col-sm-4 mb-3 mb-md-0" v-if="paymentDetails.sale && paymentDetails.sale.customer">
                      <h6 class="mb-2 border-bottom pb-2">Customer Info:</h6>
                      <div><strong>{{paymentDetails.sale.customer.customer_name}}</strong></div>
                      <div>
                        {{paymentDetails.sale.customer.address}}
                      </div>
                      <div>Email: {{paymentDetails.sale.customer.email}}</div>
                      <div>Phone: {{paymentDetails.sale.customer.phone_primary}}</div>
                    </div>
                    

                    <div class="col-sm-4 mb-3 mb-md-0" v-if="paymentDetails.sale">
                      <h6 class="mb-2 border-bottom pb-2">Invoice Info:</h6>
                      <div>Invoice: <strong>INV/PR-0000{{paymentDetails.sale.id}}</strong></div>
                      <div>Date: {{paymentDetails.sale.date | moment("MMM DD, YYYY")}}</div>
                      <div>Status: <strong>{{paymentDetails.sale.status}}</strong></div>
                      <div>Payment Status: <strong>{{paymentDetails.sale.payment_status}}</strong></div>
                    </div>
                  </div>

                  <div class="table-responsive-sm" v-if="paymentDetailsProducts.length > 0">
                    <table class="table table-striped">
                      <thead class="dark">
                        <tr>
                          <th class="text-center">Product</th>
                          <th class="text-center">Net Unit Price</th>
                          <th class="text-center">Quantity</th>
                          <th class="text-center">Discount</th>
                          <th class="text-center">Tax</th>
                          <th class="text-center">Sub Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(paymentsProducts, index) in paymentDetailsProducts" :key="index">
                          <td>
                            <div class="company-details">
                              <div class="company-logo" v-if="paymentsProducts.product.picture" >
                                <img
                                  :src="API_URL + paymentsProducts.product.picture"
                                  alt=""
                                />
                              </div>
                              <div class="company-logo" v-else>
                                <img
                                  src="../../assets/images/product-image.png"
                                  alt=""
                                />
                              </div>
                              <h6 class="company-name">
                                {{paymentsProducts.product_name}}
                              </h6>
                            </div>
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.price}}
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.quantity}}
                          </td>

                          <td col="span" class="text-center">
                            {{paymentsProducts.product_discount_amount}}
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.product_tax_amount}}
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.sub_total}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 pr-md-0 mt-3 ml-md-auto" v-if="paymentDetails.sale"> 
                      <table class="table">
                        <tbody>
                          <tr>
                            <td class="left"><strong>Discount ({{paymentDetails.sale.discount_percentage}}%)</strong></td>
                            <td class="right">{{this.$currency}} {{paymentDetails.sale.discount_amount}}</td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Tax ({{paymentDetails.sale.tax_percentage}}%)</strong></td>
                            <td class="right">{{this.$currency}} {{paymentDetails.sale.tax_amount}}</td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Shipping</strong></td>
                            <td class="right">{{this.$currency}} {{paymentDetails.sale.shipping_amount}}</td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Grand Total</strong></td>
                            <td class="right"><strong>{{this.$currency}} {{paymentDetails.sale.total_amount}}</strong></td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Paid Amount</strong></td>
                            <td class="right"><strong>{{this.$currency}} {{paymentDetails.sale.paid_amount}}</strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="mobilePreview"
      :paginate-elements-by-height="1400"
      filename="hehe"
      :pdf-quality="2"
      :manual-pagination="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <main class="c-main">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 p-0">
              <div class="card">
                <div class="card-header" v-if="paymentDetails.sale">
                  <div class="ref">Reference: <strong>{{paymentDetails.sale.reference}} </strong></div>
                </div>
                <div class="card-body">
                  <div class="row mb-4">
                    <div class="col-sm-4 mb-3 mb-md-0" v-if="this.user && this.user.data.companyData!=null ">
                      <h6 class="mb-2 border-bottom pb-2">Company Info:</h6>
                      <div><strong>{{this.user.data.companyData.company_name}}</strong></div>
                      <div>{{this.user.data.companyData.address}}</div>
                      <div>Email: {{this.user.data.companyData.company_email}}</div>
                      <div>Phone: {{this.user.data.companyData.phone_number}}</div>
                    </div>

                    <div class="col-sm-4 mb-3 mb-md-0" v-if="paymentDetails.sale && paymentDetails.sale.customer">
                      <h6 class="mb-2 border-bottom pb-2">Customer Info:</h6>
                      <div><strong>{{paymentDetails.sale.customer.customer_name}}</strong></div>
                      <div>
                        {{paymentDetails.sale.customer.address}}
                      </div>
                      <div>Email: {{paymentDetails.sale.customer.email}}</div>
                      <div>Phone: {{paymentDetails.sale.customer.phone_primary}}</div>
                    </div>

                    <div class="col-sm-4 mb-3 mb-md-0" v-if="paymentDetails.sale">
                      <h6 class="mb-2 border-bottom pb-2">Invoice Info:</h6>
                      <div>Invoice: <strong>INV/PR-0000{{paymentDetails.sale.id}}</strong></div>
                      <div>Date: {{paymentDetails.sale.date | moment("MMM DD, YYYY")}}</div>
                      <div>Status: <strong>{{paymentDetails.sale.status}}</strong></div>
                      <div>Payment Status: <strong>{{paymentDetails.sale.payment_status}}</strong></div>
                    </div>
                  </div>

                  <div class="table-responsive-sm" v-if="paymentDetailsProducts.length > 0">
                    <table class="table table-striped">
                      <thead class="dark">
                        <tr>
                          <th class="text-center">Product</th>
                          <th class="text-center">Net Unit Price</th>
                          <th class="text-center">Quantity</th>
                          <th class="text-center">Discount</th>
                          <th class="text-center">Tax</th>
                          <th class="text-center">Sub Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(paymentsProducts, index) in paymentDetailsProducts" :key="index">
                          <td>
                            <div class="company-details">
                              <div class="company-logo" v-if="paymentsProducts.product.picture" >
                                <img
                                  :src="API_URL + paymentsProducts.product.picture"
                                  alt=""
                                />
                              </div>
                              <div class="company-logo" v-else>
                                <img
                                  src="../../assets/images/product-image.png"
                                  alt=""
                                />
                              </div>
                              <h6 class="company-name">
                                {{paymentsProducts.product_name}}
                              </h6>
                            </div>
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.price}}
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.quantity}}
                          </td>

                          <td col="span" class="text-center">
                            {{paymentsProducts.product_discount_amount}}
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.product_tax_amount}}
                          </td>
                          <td col="span" class="text-center">
                            {{paymentsProducts.sub_total}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 pr-md-0 mt-3 ml-md-auto" v-if="paymentDetails.sale"> 
                      <table class="table">
                        <tbody>
                          <tr>
                            <td class="left"><strong>Discount ({{paymentDetails.sale.discount_percentage}}%)</strong></td>
                            <td class="right">{{this.$currency}} {{paymentDetails.sale.discount_amount}}</td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Tax ({{paymentDetails.sale.tax_percentage}}%)</strong></td>
                            <td class="right">{{this.$currency}} {{paymentDetails.sale.tax_amount}}</td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Shipping</strong></td>
                            <td class="right">{{this.$currency}} {{paymentDetails.sale.shipping_amount}}</td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Grand Total</strong></td>
                            <td class="right"><strong>{{this.$currency}} {{paymentDetails.sale.total_amount}}</strong></td>
                          </tr>
                          <tr>
                            <td class="left"><strong>Paid Amount</strong></td>
                            <td class="right"><strong>{{this.$currency}} {{paymentDetails.sale.paid_amount}}</strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { mapGetters } from "vuex";
export default {
  name: "PaymentDetail",
  props:{
    paymentDetails: {
    type:Object
  },
  paymentDetailsProducts: {
    type:Array
  }
  },
  data: function() {
    return {
      isLoadingArray: [],
      mobilePreview: true,
      API_URL: this.$productStorageURL,
    };
  },
  components: {
    
    VueHtml2pdf,
      
  },
  computed: {
     ...mapGetters("auth", ["user"]),
    htmlToPdfOptions() {
      return {
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          useCORS: true,
        },
      };
    },
  },
  methods: {
    print: function(index) {
      this.$set(this.isLoadingArray, index, true);
      this.$refs.html2Pdf.generatePdf();
      setTimeout(() => {
        this.$set(this.isLoadingArray, index, false);
      }, 500);
    },
  },
  mounted(){
  }
};
</script>

<style></style>
